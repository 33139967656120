import { ApiModuleBase } from '../ApiModuleBase.js'
import download from 'downloadjs'

export class DocHub extends ApiModuleBase {
    static get namespace () {
        return 'dochub'
    }

    static get urlNamespace () {
        return 'dochub'
    }

    getDocumentSessions (brokerId, status = 'exported', limit = 1000) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions`, { status, limit })
    }

    getDocumentSession (brokerId, sessionId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}`)
    }

    getSessionFiles (brokerId, sessionId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/files`)
    }

    async downloadSessionFile (brokerId, sessionId, fileUid) {
        const response = await this.client.get(
            `/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/files/${fileUid}`,
            {
                responseType: 'blob'
            }
        )
        if (response.status === 200) {
            download(response.data, response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', ''))
        }
    }

    async downloadSessionDocument (brokerId, sessionId, documentId) {
        const response = await this.client.get(
            `/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/documents/${documentId}/pdf`,
            {
                responseType: 'blob',
                params: {
                    download: true
                }
            }
        )
        if (response.status === 200) {
            download(response.data, response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', ''))
        }
    }

    async getBulkDownloads (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/bulk-downloads`)
    }

    async createBulkDownload (brokerId, sessionIds) {
        return this.doPostRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/bulk-downloads`, { sessionIds })
    }

    async downloadSessionArchive (brokerId, sessionId, documentId) {
        const response = await this.client.get(
            `/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/archive`,
            {
                responseType: 'blob'
            }
        )
        if (response.status === 200) {
            download(response.data, response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', ''))
        }
    }

    getSessionDocuments (brokerId, sessionId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/documents`)
    }

    getDocumentSessionBroker (brokerId, sessionId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/document-sessions/${sessionId}/broker`)
    }
}
