import { ApiModuleBase } from '../ApiModuleBase.js'

export class IZDocs extends ApiModuleBase {
    static get namespace () {
        return 'izdocs'
    }

    static get urlNamespace () {
        return 'izdocs'
    }

    getBrokers (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}`)
    }

    getBrokersEnum () {
        return this.doGetRequest(`/${this.ns}/brokers/enum`)
    }

    getBrokerAgents (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/agents`)
    }

    getBrokerAgent (brokerId, agentId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/agents/${agentId}`)
    }

    updateAgent (brokerId, agentId, data) {
        return this.doPatchRequest(`/${this.ns}/brokers/${brokerId}/agents/${agentId}`, data)
    }

    getBrokerAgentGroups (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/agent-groups`)
    }

    getBrokerAgentGroup (brokerId, agentGroupId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/agent-groups/${agentGroupId}`)
    }

    addAgentsToAgentGroup (brokerId, agentGroupId, data) {
        return this.doPatchRequest(`/${this.ns}/brokers/${brokerId}/agent-groups/${agentGroupId}/agents`, data)
    }

    removeAgentsFromAgentGroup (brokerId, agentGroupId, data) {
        return this.doDeleteRequest(`/${this.ns}/brokers/${brokerId}/agent-groups/${agentGroupId}/agents`, data)
    }

    addAgentGroupsToAgent (brokerId, agentId, data) {
        return this.doPatchRequest(`/${this.ns}/brokers/${brokerId}/agents/${agentId}/agent-groups`, data)
    }

    removeAgentGroupsFromAgent (brokerId, agentId, data) {
        return this.doDeleteRequest(`/${this.ns}/brokers/${brokerId}/agents/${agentId}/agent-groups`, data)
    }
}
