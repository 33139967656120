import { ApiModuleBase } from '../ApiModuleBase.js'

export class Capitol extends ApiModuleBase {
    static get namespace () {
        return 'capitol'
    }

    static get urlNamespace () {
        return 'capitol'
    }

    getBrokerClients (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/clients`)
    }

    getBrokerPositions (brokerId, filters) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/positions`, filters)
    }

    getBrokerPositionsFilters (brokerId, filters) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/positions/filters`, filters)
    }

    getBrokerLastOrders (brokerId, filters) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/last-orders`, filters)
    }

    getBrokerLastOrdersFilters (brokerId, filters) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}/last-orders/filters`, filters)
    }
}
