import { Auth } from './Auth.js'
import { IZDocs } from './IZDocs.js'
import { Profile } from './Profile.js'
import { Users } from './Users.js'
import { Capitol } from './Capitol.js'
import { DocHub } from './DocHub.js'
import { FileStorage } from './FileStorage.js'

export default [
    Auth,
    Profile,
    Users,
    IZDocs,
    Capitol,
    DocHub,
    FileStorage
]
