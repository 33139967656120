import { axiosClient as axios } from './AxiosPlugin.js'
import Vue from 'vue'

class User {
    constructor (
        onLoggedInCallback,
        onLoggedOutCallback
    ) {
        this.loggedInCallbacks = []
        this.loggedOutCallbacks = []

        this.onLogin(onLoggedInCallback)
        this.onLogout(onLoggedOutCallback)

        this.vm = new Vue({
            data: {
                isLoggedIn: false,
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                disabled: false,
                roles: [],
                izdocsCompanyId: null,
                isImpersonated: false,
                impersonatedUser: null,
                allowAPICalls: false
            },
            render () {
                return null
            }
        })
    }

    onLoggedIn (data) {
        this.vm.isLoggedIn = true
        this.vm.email = data.email
        this.vm.firstName = data.firstName
        this.vm.lastName = data.lastName
        this.vm.phoneNumber = data.phoneNumber
        this.vm.disabled = data.disabled
        this.vm.roles = data.roles
        this.vm.izdocsCompanyId = data.izdocsCompanyId
        this.vm.isImpersonated = data.isImpersonated
        this.vm.impersonatedUser = data.impersonatedUser
        this.vm.allowAPICalls = data.allowAPICalls

        for (const fn of this.loggedInCallbacks) {
            fn(this)
        }
    }

    onLoggedOut () {
        this.vm.isLoggedIn = false
        this.vm.email = null
        this.vm.firstName = null
        this.vm.lastName = null
        this.vm.phoneNumber = null
        this.vm.disabled = false
        this.vm.roles = []
        this.vm.izdocsCompanyId = null
        this.vm.isImpersonated = false
        this.vm.impersonatedUser = null
        this.vm.allowAPICalls = false

        for (const fn of this.loggedOutCallbacks) {
            fn(this)
        }
    }

    isInRole (roles) {
        if (!Array.isArray(roles)) {
            roles = [`${roles}`]
        }
        if (this.vm.isLoggedIn) {
            const userRoles = (this.vm.isImpersonated) ? this.vm.impersonatedUser.roles : this.vm.roles
            for (const role of roles) {
                if (userRoles.includes(role)) {
                    return true
                }
            }
        }
        return false
    }

    get username () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.email : this.vm.email
        }
        return null
    }

    get email () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.email : this.vm.email
        }
        return null
    }

    get firstName () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.firstName : this.vm.firstName
        }
        return null
    }

    get lastName () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.lastName : this.vm.lastName
        }
        return null
    }

    get phoneNumber () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.phoneNumber : this.vm.phoneNumber
        }
        return null
    }

    get disabled () {
        if (this.vm.isLoggedIn) {
            return ((this.vm.isImpersonated) ? this.vm.impersonatedUser.disabled : this.vm.disabled) === true
        }
        return false
    }

    get roles () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.roles : this.vm.roles
        }
        return []
    }

    get izdocsCompanyId () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.izdocsCompanyId : this.vm.izdocsCompanyId
        }
        return null
    }

    get isImpersonated () {
        return this.vm.isImpersonated
    }

    get isLoggedIn () {
        return this.vm.isLoggedIn
    }

    get allowAPICalls () {
        if (this.vm.isLoggedIn) {
            return (this.vm.isImpersonated) ? this.vm.impersonatedUser.allowAPICalls : this.vm.allowAPICalls
        }
        return false
    }

    onLogin (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedInCallbacks.push(callbackFn)
        }
    }

    onLogout (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedOutCallbacks.push(callbackFn)
        }
    }

    async reload () {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_BASE}/api/auth/me`)
            if (response.status === 200) {
                this.onLoggedIn(response.data)
            } else {
                this.onLoggedOut()
            }
        } catch (error) {
            // nothing
        }
        return this
    }
}

export const UserPlugin = {
    install (Vue) {
        const user = new User()
        Vue.prototype.$user = user
        Vue.prototype.$user.reload()
    }
}
