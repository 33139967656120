<template>
    <div class="navbar-top-bar">
        <div class="container">
            <div class="navbar-top-bar-row">
                <div class="navbar-top-bar-logo">
                    <a href="/" class="site-brand" title="CYRRUS FX" target="_self">
                        <img src="/img/logo-cyrrus.svg" width="215" height="35" alt="CYRRUS, a.s." class="site-brand-logo"/>
                    </a>
                </div>

                <div class="navbar-top-bar-addons">
                    <div class="d-flex justify-content-end align-items-center align-content-center">
                        <div class="mr-3">
                            <span class="iconify text-cyrrus-orange" data-icon="mdi:phone"></span>
                            <a class="text-black text-decoration-none" href="tel:+420800297787" target="_self">
                                +420 <strong>800 297 787</strong>
                            </a>
                        </div>
                        <div class="lang-switcher" v-if="$settings.ENABLE_LANG_SWITCHER">
                            <a href="javascript:void(0);" @click.prevent="onToggleLang" target="_self" class="lang-switcher-link">
                                <img :src="$t('navigation.flag')" class="img img-fluid d-inline-block" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    methods: {
        onToggleLang () {
            this.$store.commit('setLang', ((this.$store.state.lang === 'cz') ? 'en' : 'cz'))
            this.$i18n.locale = `${this.$store.state.lang}`
        }
    }
}
</script>
