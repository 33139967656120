import { ApiModuleBase } from '../ApiModuleBase.js'

export class Auth extends ApiModuleBase {
    static get namespace () {
        return 'auth'
    }

    static get urlNamespace () {
        return 'auth'
    }

    login (username, password) {
        return this.doPostRequest(`/${this.ns}/login`, { username, password })
    }

    logout () {
        return this.doGetRequest(`/${this.ns}/logout`)
    }

    issueCode () {
        return this.doHeadRequest(`/${this.ns}/otp`)
    }

    verifyMfaCode (otpCode) {
        return this.doPostRequest(`/${this.ns}/otp`, { code: otpCode })
    }
}
