import { ApiModuleBase } from '../ApiModuleBase.js'
import download from 'downloadjs'

export class FileStorage extends ApiModuleBase {
    static get namespace () {
        return 'fileStorage'
    }

    static get urlNamespace () {
        return 'file-storage'
    }

    getBrokerFiles (brokerId) {
        return this.doGetRequest(`/${this.ns}/brokers/${brokerId}`)
    }

    browseFolder (brokerId, folderName) {
        return this.doPatchRequest(`/${this.ns}/brokers/${brokerId}/directories`, {
            prefix: folderName
        })
    }

    getBrokerUploadUrl (brokerId) {
        return `${this.client.defaults.baseURL}/${this.ns}/brokers/${brokerId}`
    }

    async downloadBrokerFile (brokerId, file) {
        const response = await this.client.get(
            `/${this.ns}/brokers/${brokerId}/files/download`,
            {
                responseType: 'blob',
                params: {
                    file
                }
            }
        )
        if (response.status === 200) {
            download(response.data, response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', ''))
        }
    }

    async deleteBrokerFile (brokerId, file) {
        return this.doDeleteRequest(
            `/${this.ns}/brokers/${brokerId}/files`,
            {
                file
            }
        )
    }
}
