import { ApiModuleBase } from '../ApiModuleBase.js'

export class Users extends ApiModuleBase {
    static get namespace () {
        return 'users'
    }

    static get urlNamespace () {
        return 'users'
    }

    impersonate (userId) {
        return this.doHeadRequest(`/${this.ns}/${userId}/impersonate`)
    }

    stopImpersonation () {
        return this.doDeleteRequest(`/${this.ns}/impersonate`)
    }
}
