import { ApiModuleBase } from '../ApiModuleBase.js'

export class Profile extends ApiModuleBase {
    static get namespace () {
        return 'profile'
    }

    static get urlNamespace () {
        return 'profile'
    }

    updateProfile (dataset) {
        return this.doPatchRequest(`/${this.ns}`, { ...dataset })
    }

    changePassword (oldPassword, newPassword) {
        return this.doPatchRequest(`/${this.ns}/password`, { password: oldPassword, newPassword })
    }

    confirmPhoneChange (otp) {
        return this.doPostRequest(`/${this.ns}/confirmation/phone`, { otp })
    }
}
