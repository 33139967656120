import Router from 'vue-router'
import ComponentLoader from '../../Components/ComponentLoader.vue'
import ComponentError from '../../Components/ComponentError.vue'
import { HeadRequestGuard } from './HeadRequestGuard.js'
const loaderSettings = {
    loading: ComponentLoader,
    error: ComponentError,
    delay: 10,
    timeout: 30 * 1000
}

const createRouter = (appGuard = null) => {
    return new Router({
        mode: 'history',
        base: '/',
        routes: [
            {
                path: '',
                name: 'Index',
                component: () => import(/* webpackChunkName: "index" */ '../../Views/Index/Index.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnterLogin()
            },
            {
                path: '/email-changed',
                name: 'EmailChanged',
                component: () => import(/* webpackChunkName: "emailChanged" */ '../../Views/EmailConfirmation/EmailChanged.vue'),
                ...loaderSettings
            },
            {
                path: '/invalid-email-change',
                name: 'InvalidEmailChange',
                component: () => import(/* webpackChunkName: "invalidEmailChange" */ '../../Views/EmailConfirmation/InvalidEmailChange.vue'),
                ...loaderSettings
            },
            {
                path: '/swagger',
                name: 'Api',
                component: () => import(/* webpackChunkName: "api" */ '../../Views/Api/ApiPage.vue'),
                ...loaderSettings
            },
            {
                path: '/app',
                component: () => import(/* webpackChunkName: "landing" */ '../../Views/App/Landing.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnter(),
                children: [
                    {
                        path: '',
                        name: 'Landing',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "appLanding" */ '../../Views/App/Views/Index/Index.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'profile',
                        name: 'Profile',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "profile" */ '../../Views/App/Views/Profile/Profile.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'agent-groups',
                        name: 'AgentGroups',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "agentGroupsLanding" */ '../../Views/App/Views/AgentGroups/AgentGroups.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'agents',
                        name: 'Agents',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "agents" */ '../../Views/App/Views/Agents/Agents.vue'),
                                ...loaderSettings
                            }
                        },
                        beforeEnter: appGuard.beforeEnter('izUserAdmin')
                    },
                    {
                        path: 'clients',
                        component: () => import(/* webpackChunkName: "clientsLanding" */ '../../Views/App/Views/Clients/Clients.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter('izClientsAdmin'),
                        children: [
                            {
                                path: '',
                                name: 'ClientsProperty',
                                component () {
                                    return {
                                        component: import(/* webpackChunkName: "clientsProperty" */ '../../Views/App/Views/Clients/Views/Property/Property.vue'),
                                        ...loaderSettings
                                    }
                                }
                            },
                            {
                                path: 'positions',
                                name: 'ClientsPositions',
                                component () {
                                    return {
                                        component: import(/* webpackChunkName: "clientsPositions" */ '../../Views/App/Views/Clients/Views/Positions/Positions.vue'),
                                        ...loaderSettings
                                    }
                                },
                                props: (route) => {
                                    return {
                                        client: route.query.client,
                                        agent: route.query.agent,
                                        stock: route.query.stock
                                    }
                                }
                            },
                            {
                                path: 'last-orders',
                                name: 'ClientsLastOrders',
                                component () {
                                    return {
                                        component: import(/* webpackChunkName: "clientsLastOrders" */ '../../Views/App/Views/Clients/Views/LastOrders/LastOrders.vue'),
                                        ...loaderSettings
                                    }
                                },
                                props: (route) => {
                                    return {
                                        client: route.query.client,
                                        agent: route.query.agent,
                                        stock: route.query.stock
                                    }
                                }
                            }
                        ]
                    },
                    {
                        path: 'contracts',
                        component: () => import(/* webpackChunkName: "contractsLanding" */ '../../Views/App/Views/Contracts/Contracts.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter('izContractsAdmin'),
                        children: [
                            {
                                path: '',
                                name: 'ContractsAccepted',
                                component () {
                                    return {
                                        component: import(/* webpackChunkName: "contractsAccepted" */ '../../Views/App/Views/Contracts/Views/Accepted/Accepted.vue'),
                                        ...loaderSettings
                                    }
                                }
                            },
                            {
                                path: 'session/:sessionId',
                                name: 'ContractsDetail',
                                component () {
                                    return {
                                        component: import(/* webpackChunkName: "contractsDetail" */ '../../Views/App/Views/Contracts/Views/Detail/Detail.vue'),
                                        ...loaderSettings
                                    }
                                },
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'storage',
                        name: 'Storage',
                        component: () => import(/* webpackChunkName: "storageLanding" */ '../../Views/App/Views/Storage/Storage.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter('izStorageAdmin')
                    }
                ]
            },
            {
                path: '/admin',
                component: () => import(/* webpackChunkName: "adminLanding" */ '../../Views/Admin/Landing.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnter('admin'),
                children: [
                    {
                        path: '',
                        name: 'AdminLanding',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "adminIndex" */ '../../Views/Admin/Views/Index/Index.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'users',
                        name: 'AdminUsers',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "users" */ '../../Views/Admin/Views/Users/Users.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'storage/:brokerId?',
                        name: 'AdminStorage',
                        component: () => import(/* webpackChunkName: "adminStorage" */ '../../Views/Admin/Views/Storage/Storage.vue'),
                        ...loaderSettings,
                        props: true
                    }
                ]
            }
        ]
    })
}

export class RouterFactory {
    constructor (appSettings) {
        this.appSettings = appSettings
        this.appGuard = new HeadRequestGuard(this.appSettings.APP_GUARD_URL, { name: 'Index' })
        this._router = createRouter(this.appGuard)
    }

    get router () {
        return this._router
    }
}
