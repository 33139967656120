<template>
    <div id="app" class="main-flex">
        <header class="main-header shadow-sm">
            <top-bar />
            <app-navbar>
               <b-nav-item-dropdown v-if="$user.isInRole(['izUserAdmin'])">
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account-multiple"></span> {{$t('navigation.agents')}}
                    </template>
                    <b-dropdown-item :to="{ name: 'Agents' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account-multiple"></span>{{$t('navigation.agentsOverview')}}
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'AgentGroups' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account-group"></span>{{$t('navigation.agentGroups')}}
                    </b-dropdown-item>
               </b-nav-item-dropdown>

                <b-nav-item-dropdown v-if="$user.isInRole(['izClientsAdmin'])">
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:abacus"></span> {{$t('navigation.clients')}}
                    </template>
                    <b-dropdown-item :to="{ name: 'ClientsProperty' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:currency-usd"></span>{{$t('navigation.clientsProperty')}}
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'ClientsPositions' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:chart-bar-stacked"></span>{{$t('navigation.clientsPositions')}}
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'ClientsLastOrders' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:cart-arrow-right"></span>{{$t('navigation.clientsLastOrders')}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown v-if="$user.isInRole(['izContractsAdmin'])">
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:file-document-multiple-outline"></span> {{$t('navigation.contracts')}}
                    </template>
                    <b-dropdown-item :to="{ name: 'ContractsAccepted' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:file-check-outline"></span>{{$t('navigation.contractsAccepted')}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item
                    :to="{ name: 'Storage' }"
                    v-if="$user.isInRole(['izStorageAdmin'])"
                    :active="$route.name === 'Storage'"
                >
                    <span class="iconify mr-2 text-primary" data-icon="mdi:download"></span>{{$t('navigation.files')}}
                </b-nav-item>

                <b-nav-item
                    :to="{ name: 'AdminStorage' }"
                    v-if="$user.isInRole(['admin'])"
                    :active="$route.name === 'AdminStorage'"
                >
                    <span class="iconify mr-2 text-primary" data-icon="mdi:download"></span>{{$t('navigation.files')}}
                </b-nav-item>

                <b-nav-item-dropdown v-if="$user.isInRole(['admin'])">
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:cog"></span> {{$t('navigation.administration')}}
                    </template>
                    <b-dropdown-item :to="{ name: 'AdminUsers' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account-multiple"></span>{{$t('navigation.users')}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown>
                    <template #button-content>
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account"></span>{{user.firstName}} {{user.lastName}}
                    </template>
                    <b-dropdown-item :href="'javascript:void(0);'" @click.prevent="onStopImpersonation" v-if="$user.isImpersonated">
                        <span class="iconify mr-2 text-danger" data-icon="mdi:close"></span>{{$t('navigation.stopImpersonation')}}
                    </b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'Profile' }">
                        <span class="iconify mr-2 text-primary" data-icon="mdi:account-cog"></span>{{$t('navigation.profile')}}
                    </b-dropdown-item>
                    <b-dropdown-item :href="'javascript:void(0);'" @click.prevent="onLogout">
                        <span class="iconify mr-2 text-danger" data-icon="mdi:account-lock"></span>{{$t('navigation.logout')}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </app-navbar>
        </header>

        <div :class="{
            'main-content': true,
            'bg-gradient-cyrrus-gray-dark': $route.name !== 'Index',
            'bg-gradient-cyrrus-yellow': $route.name === 'Index'
        }">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view></router-view>
            </transition>
        </div>

        <app-footer/>
    </div>
</template>

<script>
import AppFooter from './Components/Layout/Footer.vue'
import TopBar from './Components/Layout/TopBar.vue'
import AppNavbar from './Components/Layout/AppNavbar.vue'

export default {
    name: 'App',
    components: {
        AppFooter,
        TopBar,
        AppNavbar
    },
    computed: {
        user () {
            return this.$user
        }
    },
    methods: {
        async onLogout () {
            try {
                await this.$api.auth.logout()
            } catch (error) {
                console.error(error)
            }
            this.$user.onLoggedOut()
            this.$notify.success(this.$t('navigation.loggedOutMessage'))
            this.$router.replace({ name: 'Index' })
        },
        onStopImpersonation () {
            this.$api.users.stopImpersonation()
                .then(() => {
                    window.location = '/'
                }).catch((error) => {
                    console.error(error)
                })
        }
    }
}
</script>

<style lang="scss">
@import './scss/app.scss';

.form-sm .custom-select {
    height: 2.5rem;
    padding: .5rem;
    border-radius: 0;
}
</style>

<i18n>
{
    "cz": {
        "navigation": {
            "switchLang": "Switch to english",
            "flag": "/img/gb.svg",
            "logout": "Odhlásit se",
            "loggedOutMessage": "Váš účet byl odhlášen.",
            "profile": "Změna údajů",
            "administration": "Administrace",
            "users": "Uživatelé",
            "agents": "Agenti",
            "agentsOverview": "Přehled agentů",
            "clients": "Klienti",
            "clientsProperty": "Majetek",
            "clientsPositions": "Pozice",
            "clientsLastOrders": "Poslední pokyny",
            "contracts": "Smlouvy",
            "contractsAccepted": "Přijaté a zpracované",
            "files": "Soubory ke stažení",
            "stopImpersonation": "Ukončit impersonaci",
            "agentGroups": "Skupiny agentů"
        }
    },
    "en": {
        "navigation": {
            "switchLang": "Přepnout na češtinu",
            "flag": "/img/cs.svg",
            "logout": "Logout",
            "loggedOutMessage": "Your account has been logged out.",
            "profile": "User profile",
            "agents": "Agents",
            "agentsGroups": "Agents groups"
        }
    }
}
</i18n>
